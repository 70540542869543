/* eslint-disable max-len */
import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';

/**
 * This is effectively a React component that surrounds the existing
 * `src/assets/img/shared/close-button.svg` asset. This React component is a special case one, which
 * we can use to render inline svg. However, for most cases, we should use the `close-button.svg`
 * asset. In other cases, such as showing the close-button svg in the mobile upsell, rendering this
 * inline svg is preferable.
 */
const LegacyCloseButton = ({
  className,
  style,
  width = '18px',
  height = '18px',
  viewBox = '0 0 18 18',
  fill = cssVariables['--secondary-color-5'],
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    style={style}
    aria-labelledby="closeButtonTitle"
  >
    <title id="closeButtonTitle">Close Button</title>
    <path d="M11.81 9l6-6a.48.48 0 0 0 0-.7L15.7.15a.48.48 0 0 0-.7 0l-6 6-6-6a.48.48 0 0 0-.7 0L.15 2.27a.48.48 0 0 0 0 .7l6 6-6 6a.48.48 0 0 0 0 .7l2.12 2.12a.48.48 0 0 0 .7 0l6-6 6 6a.48.48 0 0 0 .7 0l2.12-2.12a.48.48 0 0 0 0-.7z" />
  </svg>
);

LegacyCloseButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  fill: PropTypes.string,
};

export default LegacyCloseButton;
