import classNames from 'clsx';
import flow from 'lodash/flow';
import get from 'lodash/get';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClampText from 'src/common/components/shared/ClampText';
import PillButton from 'src/common/components/shared/button/PillButton';
import mobileWeb from 'src/common/constants/analytics/categoryActionLabel/mobileWeb';
import {
  CTA_BUTTON_COLOR_HEX,
  CTA_BUTTON_COLOR_HEX_LANDINGONLY,
  CTA_BUTTON_TEXT,
  CTA_BUTTON_TEXT_COLOR_HEX,
  CTA_BUTTON_TEXT_COLOR_HEX_LANDINGONLY,
  CTA_BUTTON_TEXT_PREMIUM,
  MW_LANDING_BACKGROUND_IMG_URL,
  MW_LANDING_BACKGROUND_IMG_URL_LANDINGONLY,
  MW_LANDING_BYPASS_CLOSEOPTION_COLOR_HEX,
  MW_LANDING_BYPASS_CLOSEOPTION_X_ENABLED,
  MW_LANDING_DESCRIPTION_ENABLED,
  MW_LANDING_PLAYBUTTON_ENABLED,
  MW_LANDING_PREMIUM_GUIDEIDS,
  MW_LANDING_SUBTITLE_ENABLED,
  MW_LANDING_VALUE_PROPOSITION_ENABLED,
  MW_LANDING_VALUE_PROPOSITION_ROOTGENRE_ENABLED,
  MW_LANDING_VALUE_PROPOSITION_TEXT,
} from 'src/common/constants/experiments/mobileLanding';
import {
  MOBILE_LANDING_BUTTON_LISTEN,
  MOBILE_LANDING_VALUE_PROP,
} from 'src/common/constants/localizations/mobileLanding';
import connectWithExperiments from 'src/common/decorators/connectWithExperiments';
import debounce from 'src/common/utils/debounce';
import gtagTrack from 'src/common/utils/gtagTrack';
import isLocked from 'src/common/utils/guideItem/isLocked';
import { logMobileWebActivity } from '../actions/logging';
import { LocationAndLocalizationContext } from '../providers/LocationAndLocalizationProvider';
import { selectBranchUrl } from '../selectors/app';
import css from './mobile-web-entry.module.scss';
import LegacyCloseButton from './shared/svgIcons/LegacyCloseButton';
import TuneInLogoLockup from './shared/svgIcons/TuneInLogoLockup';
import PlayCircleSolid from './shared/svgIcons/player/PlayCircleSolid';

const handleUserClick = debounce((logAction, action, label) =>
  logAction(action, label),
);

class MobileWebEntry extends Component {
  static propTypes = {
    onContinueClick: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      logMobileWebActivity: PropTypes.func.isRequired,
    }),
    userAgent: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    guideItem: PropTypes.object,
    isCategoryGuideItem: PropTypes.bool,
    isLanding: PropTypes.bool,
    branchUrl: PropTypes.string,
    [CTA_BUTTON_TEXT]: PropTypes.string,
    [CTA_BUTTON_TEXT_PREMIUM]: PropTypes.string,
    [CTA_BUTTON_TEXT_COLOR_HEX]: PropTypes.string,
    [CTA_BUTTON_TEXT_COLOR_HEX_LANDINGONLY]: PropTypes.string,
    [CTA_BUTTON_COLOR_HEX]: PropTypes.string,
    [CTA_BUTTON_COLOR_HEX_LANDINGONLY]: PropTypes.string,
    [MW_LANDING_BACKGROUND_IMG_URL]: PropTypes.string,
    [MW_LANDING_BACKGROUND_IMG_URL_LANDINGONLY]: PropTypes.string,
    [MW_LANDING_SUBTITLE_ENABLED]: PropTypes.bool,
    [MW_LANDING_DESCRIPTION_ENABLED]: PropTypes.bool,
    [MW_LANDING_VALUE_PROPOSITION_ENABLED]: PropTypes.bool,
    [MW_LANDING_VALUE_PROPOSITION_TEXT]: PropTypes.string,
    [MW_LANDING_BYPASS_CLOSEOPTION_X_ENABLED]: PropTypes.bool,
    [MW_LANDING_BYPASS_CLOSEOPTION_COLOR_HEX]: PropTypes.string,
    [MW_LANDING_PLAYBUTTON_ENABLED]: PropTypes.bool,
    [MW_LANDING_PREMIUM_GUIDEIDS]: PropTypes.string,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  componentDidMount() {
    const label = get(this.props.location, 'query.source');
    this.props.actions.logMobileWebActivity(mobileWeb.actions.show, label);
  }

  handleDialogClose(e) {
    e.preventDefault();
    gtagTrack.mobileWebBlockerDismiss();

    const { actions, onContinueClick } = this.props;

    handleUserClick(
      actions.logMobileWebActivity,
      mobileWeb.actions.tap,
      mobileWeb.labels.tuneinRedirectCornerClose,
    );
    onContinueClick();
  }

  handleAppDownloadClick(label) {
    return () => {
      const { actions } = this.props;

      gtagTrack.mobileWebInstallClick();
      handleUserClick(
        actions.logMobileWebActivity,
        mobileWeb.actions.tap,
        label,
      );
    };
  }

  render() {
    const { getLocalizedText } = this.context;
    const {
      guideItem,
      isCategoryGuideItem,
      isLanding,
      branchUrl,
      [CTA_BUTTON_TEXT]: experimentButtonText,
      [CTA_BUTTON_TEXT_PREMIUM]: experimentPremiumButtonText,
      [MW_LANDING_PREMIUM_GUIDEIDS]: experimentPremiumGuideIds,
      [CTA_BUTTON_TEXT_COLOR_HEX]: experimentButtonTextColor,
      [CTA_BUTTON_TEXT_COLOR_HEX_LANDINGONLY]:
        experimentButtonTextColorLandingOnly,
      [CTA_BUTTON_COLOR_HEX]: experimentButtonColor,
      [CTA_BUTTON_COLOR_HEX_LANDINGONLY]: experimentButtonColorLandingOnly,
      [MW_LANDING_SUBTITLE_ENABLED]: mwSubtitleEnabled,
      [MW_LANDING_DESCRIPTION_ENABLED]: mwDescriptionEnabled,
      [MW_LANDING_VALUE_PROPOSITION_ENABLED]: mwValuePropEnabled,
      [MW_LANDING_VALUE_PROPOSITION_TEXT]: mwValuePropText,
      [MW_LANDING_VALUE_PROPOSITION_ROOTGENRE_ENABLED]:
        mwValuePropRootGenreEnabled,
      [MW_LANDING_PLAYBUTTON_ENABLED]: mwPlayButtonEnabled,
      [MW_LANDING_BACKGROUND_IMG_URL]: mwBackgroundImgUrl,
      [MW_LANDING_BACKGROUND_IMG_URL_LANDINGONLY]:
        mwBackgroundImgUrlLandingOnly,
      [MW_LANDING_BYPASS_CLOSEOPTION_X_ENABLED]: isBypassXEnabled,
      [MW_LANDING_BYPASS_CLOSEOPTION_COLOR_HEX]: bypassIconColor,
    } = this.props;

    const hasLoadedGuideItem = guideItem && guideItem.guideId;
    const mwGuideItemBackgroundImageUrl =
      get(guideItem, 'properties.backgroundImage.imageUrl') ||
      get(guideItem, 'metadata.properties.backgroundImage.imageUrl');

    const mwBackgroundImgOverrideEnabled =
      mwGuideItemBackgroundImageUrl ||
      (isLanding && mwBackgroundImgUrlLandingOnly);

    const containerStyle = {}; // for background image AB testing
    if (mwGuideItemBackgroundImageUrl) {
      containerStyle.backgroundImage = `url(${mwGuideItemBackgroundImageUrl})`;
      containerStyle.backgroundColor = '#131415';
    } else if (isLanding && mwBackgroundImgUrlLandingOnly) {
      containerStyle.backgroundImage = `url(${mwBackgroundImgUrlLandingOnly})`;
      containerStyle.backgroundColor = '#000000';
    } else if (mwBackgroundImgUrl) {
      containerStyle.backgroundImage = `url(${mwBackgroundImgUrl})`;
    }

    const fill = bypassIconColor ? `#${bypassIconColor}` : undefined;

    const rootGenre = get(
      guideItem,
      'metadata.properties.classification.rootGenreClassification',
    );
    const rootGenreValuePropResourceKey = `${MOBILE_LANDING_VALUE_PROP}.${rootGenre}`;
    const rootGenreValueProp = getLocalizedText(rootGenreValuePropResourceKey);
    // If there is a root genre value prop use that else check for a global override.
    // If none, then use default
    const valueProp =
      mwValuePropRootGenreEnabled &&
      rootGenreValueProp &&
      rootGenreValuePropResourceKey !== rootGenreValueProp
        ? rootGenreValueProp
        : mwValuePropText || getLocalizedText(MOBILE_LANDING_VALUE_PROP);

    const isPremiumGuideId =
      hasLoadedGuideItem &&
      includes(experimentPremiumGuideIds, guideItem.guideId);
    const isPremiumStation =
      hasLoadedGuideItem && !isCategoryGuideItem && isLocked(guideItem);

    let buttonLabel = experimentButtonText;
    if (isPremiumGuideId || isPremiumStation) {
      buttonLabel = experimentPremiumButtonText;
    }
    buttonLabel = buttonLabel || getLocalizedText(MOBILE_LANDING_BUTTON_LISTEN);

    let buttonBackgoundColor; // for branch link button background color AB testing
    if (experimentButtonColor)
      buttonBackgoundColor = `#${experimentButtonColor}`;
    if (
      experimentButtonColorLandingOnly &&
      (isLanding || !hasLoadedGuideItem)
    ) {
      buttonBackgoundColor = `#${experimentButtonColorLandingOnly}`;
    }

    let buttonTextColor; // for branch link button text color AB testing
    if (experimentButtonTextColor)
      buttonTextColor = `#${experimentButtonTextColor} !important`;
    if (
      experimentButtonTextColorLandingOnly &&
      (isLanding || !hasLoadedGuideItem)
    ) {
      buttonTextColor = `#${experimentButtonTextColorLandingOnly} !important`;
    }

    const svgProps = {
      height: '28.5%',
      width: '28.5%',
      viewBox: '0 0 45 45',
      doNotHover: true,
      preserveAspectRatio: 'xMaxYMax',
      className: css.playButton,
    };

    return (
      <div
        data-testid="mwLandingBackground"
        className={css.mobileWebEntryContainer}
        style={containerStyle}
      >
        {isBypassXEnabled && (
          <div
            key="mwLandingCornerClose"
            id="mwLandingCornerClose"
            data-testid="mwLandingCornerClose"
            onClick={this.handleDialogClose}
          >
            <LegacyCloseButton fill={fill} className={css.closeButton} />
          </div>
        )}
        <div>
          {!mwBackgroundImgOverrideEnabled && (
            <div data-testid="mobileLandingLogo" className={css.logoContainer}>
              <TuneInLogoLockup fill="#FFF" width="180px" />
            </div>
          )}
          {mwValuePropEnabled && !mwBackgroundImgOverrideEnabled && (
            <div
              data-testid="mwLandingValueProp"
              className={css.valuePropContainer}
            >
              {valueProp}
            </div>
          )}
          <div className={css.bottomSection}>
            <div
              className={classNames(css.buttonContainer, {
                [css.buttonContainerNoGuideId]: !hasLoadedGuideItem,
              })}
            >
              {hasLoadedGuideItem ? (
                <div className={css.infoContainer}>
                  <div
                    className={classNames(css.infoHeader, {
                      [css.fullWidthInfoHeader]: isCategoryGuideItem,
                    })}
                  >
                    {guideItem.image && (
                      <div className={css.imageContainer}>
                        <img
                          data-testid="mwLandingGuideItemImage"
                          src={guideItem.image}
                          alt={
                            guideItem?.accessibilityTitle || guideItem?.title
                          }
                          className={classNames(css.image, {
                            [css.imageWithPlayButton]: mwPlayButtonEnabled,
                          })}
                        />
                      </div>
                    )}
                    <div
                      className={classNames(css.textContainer, {
                        [css.center]: !guideItem.image,
                        [css.withPlayButton]: mwPlayButtonEnabled,
                      })}
                    >
                      <ClampText
                        data-testid="mwLandingGuideItemTitle"
                        clamp={2}
                        className={classNames({
                          [css.titleWithImage]: guideItem.image,
                          [css.titleWithoutImage]: !guideItem.image,
                          [css.titleWithPlayButton]: mwPlayButtonEnabled,
                        })}
                      >
                        {guideItem.title}
                      </ClampText>
                      {!isCategoryGuideItem && mwSubtitleEnabled && (
                        <p
                          data-testid="mwLandingGuideItemSubtitle"
                          className={css.subtitle}
                        >
                          {guideItem.subtitle}
                        </p>
                      )}
                    </div>
                  </div>
                  {mwPlayButtonEnabled && guideItem.image && (
                    <a href={branchUrl} className={css.downloadLink}>
                      <PlayCircleSolid
                        {...svgProps}
                        dataTestId="mwLandingPlayIcon"
                        onClick={this.handleAppDownloadClick(
                          mobileWeb.labels.playButtonAppDownload,
                        )}
                      />
                    </a>
                  )}
                  {mwDescriptionEnabled && (
                    <ClampText
                      data-testid="mwLandingGuideItemDescription"
                      className={css.secondaryInfoContainer}
                      clamp={2}
                    >
                      {get(guideItem, 'description')}
                    </ClampText>
                  )}
                  <a href={branchUrl} className={css.downloadLink}>
                    <PillButton
                      id="mobileLandingAppDownloadWithProfile"
                      data-testid="mobileLandingAppDownloadWithProfile"
                      className={css.downloadLinkButton}
                      backgroundColor={buttonBackgoundColor}
                      textColor={buttonTextColor}
                      label={buttonLabel}
                      onClick={this.handleAppDownloadClick(
                        mobileWeb.labels.appDownload,
                      )}
                      hasRightSideIcon
                    />
                  </a>
                </div>
              ) : (
                <a href={branchUrl} className={css.downloadLink}>
                  <PillButton
                    id="mobileLandingAppDownload"
                    data-testid="mobileLandingAppDownloadButton"
                    className={css.downloadLinkButton}
                    backgroundColor={buttonBackgoundColor}
                    textColor={buttonTextColor}
                    label={buttonLabel}
                    onClick={this.handleAppDownloadClick(
                      mobileWeb.labels.appDownload,
                    )}
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  branchUrl: selectBranchUrl(state),
});

// Exporting for easier testing
export function mapDispatchToProps(dispatch) {
  const actions = { logMobileWebActivity };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default flow(
  connectWithExperiments([
    CTA_BUTTON_TEXT,
    CTA_BUTTON_TEXT_PREMIUM,
    CTA_BUTTON_TEXT_COLOR_HEX,
    CTA_BUTTON_TEXT_COLOR_HEX_LANDINGONLY,
    CTA_BUTTON_COLOR_HEX,
    CTA_BUTTON_COLOR_HEX_LANDINGONLY,
    MW_LANDING_BACKGROUND_IMG_URL,
    MW_LANDING_BACKGROUND_IMG_URL_LANDINGONLY,
    MW_LANDING_SUBTITLE_ENABLED,
    MW_LANDING_DESCRIPTION_ENABLED,
    MW_LANDING_VALUE_PROPOSITION_ENABLED,
    MW_LANDING_VALUE_PROPOSITION_TEXT,
    MW_LANDING_VALUE_PROPOSITION_ROOTGENRE_ENABLED,
    MW_LANDING_BYPASS_CLOSEOPTION_X_ENABLED,
    MW_LANDING_BYPASS_CLOSEOPTION_COLOR_HEX,
    MW_LANDING_PLAYBUTTON_ENABLED,
    MW_LANDING_PREMIUM_GUIDEIDS,
  ]),
  connect(mapStateToProps, mapDispatchToProps),
)(MobileWebEntry);
