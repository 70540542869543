import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';

/* eslint-disable max-len */
const TuneInLogoLockup = ({
  dataTestId,
  className,
  viewBox = '0 0 177.5 79.12',
  height = '100%',
  width = '100%',
  fill = cssVariables['--secondary-color-5'],
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    data-testid={dataTestId}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="TuneIn-Logo-Lockup" fill={fill}>
      <path d="m81.86 56h1.14v7.29h4.16v1h-5.3z" />
      <path d="m88.15 56h1v1.15h-1zm0 2.27h1v6h-1z" />
      <path d="m91.22 58.22 1.61 4.92 1.68-4.92h1.11l-2.27 6h-1.09l-2.22-6z" />
      <path d="m100.22 58.39a2.3 2.3 0 0 1 .92.78 2.74 2.74 0 0 1 .4 1.07 6.91 6.91 0 0 1 .09 1.33h-4.39a2.51 2.51 0 0 0 .43 1.43 1.41 1.41 0 0 0 1.24.56 1.59 1.59 0 0 0 1.26-.52 2 2 0 0 0 .38-.71h1a1.94 1.94 0 0 1 -.26.74 3 3 0 0 1 -.49.67 2.43 2.43 0 0 1 -1.14.61 3.43 3.43 0 0 1 -.82.09 2.54 2.54 0 0 1 -1.9-.82 3.22 3.22 0 0 1 -.78-2.3 3.53 3.53 0 0 1 .77-2.32 2.6 2.6 0 0 1 2-.9 2.73 2.73 0 0 1 1.29.29zm.37 2.37a2.74 2.74 0 0 0 -.29-1 1.47 1.47 0 0 0 -1.38-.76 1.51 1.51 0 0 0 -1.16.51 1.87 1.87 0 0 0 -.5 1.28z" />
      <path d="m107 61.59a2.33 2.33 0 0 0 .32 1.14 2.1 2.1 0 0 0 1.9.83 3.31 3.31 0 0 0 1.11-.18 1.28 1.28 0 0 0 1-1.26 1.07 1.07 0 0 0 -.41-1 4 4 0 0 0 -1.3-.49l-1.08-.25a4.88 4.88 0 0 1 -1.51-.55 1.75 1.75 0 0 1 -.77-1.55 2.41 2.41 0 0 1 .76-1.84 3 3 0 0 1 2.13-.71 3.62 3.62 0 0 1 2.15.63 2.3 2.3 0 0 1 .89 2h-1.06a2 2 0 0 0 -.35-1 2 2 0 0 0 -1.67-.65 1.85 1.85 0 0 0 -1.36.42 1.31 1.31 0 0 0 -.42 1 1 1 0 0 0 .49.89 7.15 7.15 0 0 0 1.46.45l1.13.26a3.4 3.4 0 0 1 1.25.53 2 2 0 0 1 .77 1.68 2.12 2.12 0 0 1 -1 2 4.25 4.25 0 0 1 -2.27.59 3.33 3.33 0 0 1 -2.36-.79 2.72 2.72 0 0 1 -.83-2.12z" />
      <path d="m113.74 58.25h1v.8a2.49 2.49 0 0 1 .66-.64 2.22 2.22 0 0 1 1.21-.33 2.29 2.29 0 0 1 1.75.78 3.24 3.24 0 0 1 .72 2.25 3.45 3.45 0 0 1 -1 2.82 2.34 2.34 0 0 1 -1.53.53 2.09 2.09 0 0 1 -1.15-.29 2.72 2.72 0 0 1 -.6-.59v3.09h-1zm3.82 4.75a2.77 2.77 0 0 0 .48-1.77 3.34 3.34 0 0 0 -.21-1.25 1.54 1.54 0 0 0 -2.9.06 4.15 4.15 0 0 0 -.21 1.43 3 3 0 0 0 .21 1.19 1.53 1.53 0 0 0 2.63.34z" />
      <path d="m124.63 58.82a3.08 3.08 0 0 1 .8 2.29 3.94 3.94 0 0 1 -.71 2.41 2.54 2.54 0 0 1 -2.19 1 2.43 2.43 0 0 1 -2-.85 3.3 3.3 0 0 1 -.73-2.26 3.62 3.62 0 0 1 .77-2.42 2.57 2.57 0 0 1 2.06-.9 2.75 2.75 0 0 1 2 .73zm-.63 4a4 4 0 0 0 .37-1.7 3 3 0 0 0 -.27-1.37 1.5 1.5 0 0 0 -1.46-.84 1.45 1.45 0 0 0 -1.34.71 3.33 3.33 0 0 0 -.42 1.71 2.88 2.88 0 0 0 .42 1.67 1.46 1.46 0 0 0 1.33.64 1.39 1.39 0 0 0 1.37-.8z" />
      <path d="m126.7 58.22h1v1a2.31 2.31 0 0 1 .58-.74 1.5 1.5 0 0 1 1.06-.43h.1.23v1.08h-.17-.16a1.44 1.44 0 0 0 -1.18.49 1.7 1.7 0 0 0 -.41 1.14v3.47h-1z" />
      <path d="m130.72 56.54h1v1.68h1v.83h-1v3.95a.45.45 0 0 0 .21.43 1 1 0 0 0 .4.06h.35v.8a1.35 1.35 0 0 1 -.36.07 2.54 2.54 0 0 1 -.41 0 1.08 1.08 0 0 1 -1-.37 1.59 1.59 0 0 1 -.26-.94v-4h-.81v-.83h.81z" />
      <path d="m134.33 62.37a1.5 1.5 0 0 0 .26.78 1.68 1.68 0 0 0 1.36.49 2.07 2.07 0 0 0 1-.25.8.8 0 0 0 .44-.76.64.64 0 0 0 -.35-.59 4.36 4.36 0 0 0 -.88-.29l-.82-.2a4.12 4.12 0 0 1 -1.16-.43 1.27 1.27 0 0 1 -.66-1.14 1.76 1.76 0 0 1 .62-1.39 2.55 2.55 0 0 1 1.69-.53 2.36 2.36 0 0 1 2 .8 1.7 1.7 0 0 1 .37 1.1h-1a1.16 1.16 0 0 0 -.24-.62 1.62 1.62 0 0 0 -1.25-.4 1.48 1.48 0 0 0 -.89.22.68.68 0 0 0 -.3.58.71.71 0 0 0 .4.63 2.48 2.48 0 0 0 .68.24l.68.17a5 5 0 0 1 1.49.51 1.35 1.35 0 0 1 .6 1.22 1.88 1.88 0 0 1 -.62 1.4 2.69 2.69 0 0 1 -1.89.58 2.52 2.52 0 0 1 -1.93-.61 2.26 2.26 0 0 1 -.61-1.51z" />
      <path d="m139.75 63h1.18v1.23h-1.18z" />
      <path d="m146 56h1.61l2.39 7 2.37-7h1.63v8.28h-1.07v-4.91c0-.17 0-.45 0-.84s0-.81 0-1.26l-2.36 7h-1.11l-2.39-7v.26.93s0 .72 0 .91v4.89h-1.07z" />
      <path d="m156.56 58.22v4a1.84 1.84 0 0 0 .14.76 1 1 0 0 0 1 .54 1.39 1.39 0 0 0 1.4-1 3.75 3.75 0 0 0 .2-1.39v-3h1v6h-1v-.89a2.1 2.1 0 0 1 -.48.58 2.06 2.06 0 0 1 -1.38.47 1.77 1.77 0 0 1 -1.73-.86 2.56 2.56 0 0 1 -.25-1.23v-3.98z" />
      <path d="m162.57 62.37a1.5 1.5 0 0 0 .26.78 1.66 1.66 0 0 0 1.36.49 2.07 2.07 0 0 0 1-.25.82.82 0 0 0 .44-.76.64.64 0 0 0 -.35-.59 4.36 4.36 0 0 0 -.88-.29l-.82-.2a4 4 0 0 1 -1.16-.43 1.28 1.28 0 0 1 -.67-1.14 1.74 1.74 0 0 1 .63-1.39 2.54 2.54 0 0 1 1.68-.53 2.37 2.37 0 0 1 2 .8 1.76 1.76 0 0 1 .37 1.1h-1a1.1 1.1 0 0 0 -.25-.62 1.59 1.59 0 0 0 -1.24-.4 1.48 1.48 0 0 0 -.89.22.68.68 0 0 0 -.3.58.71.71 0 0 0 .4.63 2.4 2.4 0 0 0 .67.24l.69.17a5 5 0 0 1 1.49.51 1.35 1.35 0 0 1 .59 1.22 1.84 1.84 0 0 1 -.62 1.4 2.65 2.65 0 0 1 -1.88.58 2.56 2.56 0 0 1 -1.94-.61 2.25 2.25 0 0 1 -.6-1.51z" />
      <path d="m167.74 56h1v1.15h-1zm0 2.27h1v6h-1z" />
      <path d="m174.3 58.54a2.38 2.38 0 0 1 .77 1.71h-1a1.89 1.89 0 0 0 -.41-.93 1.31 1.31 0 0 0 -1-.36 1.42 1.42 0 0 0 -1.39.94 3.79 3.79 0 0 0 -.27 1.52 2.88 2.88 0 0 0 .38 1.52 1.31 1.31 0 0 0 1.21.62 1.33 1.33 0 0 0 1-.38 2.07 2.07 0 0 0 .51-1.06h1a2.4 2.4 0 0 1 -2.58 2.32 2.31 2.31 0 0 1 -1.89-.87 3.32 3.32 0 0 1 -.7-2.16 3.63 3.63 0 0 1 .77-2.48 2.5 2.5 0 0 1 2-.88 2.64 2.64 0 0 1 1.6.49z" />
      <path d="m176.33 63h1.17v1.23h-1.17z" />
      <path d="m81.86 70.61h1.32l4.14 6.69v-6.69h1.07v8.28h-1.26l-4.2-6.68v6.68h-1.07z" />
      <path d="m93.8 73a2.27 2.27 0 0 1 .91.78 2.75 2.75 0 0 1 .41 1.07 6.91 6.91 0 0 1 .09 1.33h-4.4a2.65 2.65 0 0 0 .43 1.48 1.45 1.45 0 0 0 1.25.55 1.59 1.59 0 0 0 1.26-.52 1.73 1.73 0 0 0 .37-.71h1a2.13 2.13 0 0 1 -.26.74 3 3 0 0 1 -.5.67 2.35 2.35 0 0 1 -1.13.61 3.52 3.52 0 0 1 -.83.09 2.56 2.56 0 0 1 -1.9-.82 3.22 3.22 0 0 1 -.78-2.3 3.53 3.53 0 0 1 .79-2.37 2.59 2.59 0 0 1 2-.9 2.73 2.73 0 0 1 1.29.3zm.36 2.37a2.58 2.58 0 0 0 -.28-1 1.47 1.47 0 0 0 -1.38-.74 1.53 1.53 0 0 0 -1.17.51 1.92 1.92 0 0 0 -.5 1.28z" />
      <path d="m97 72.85 1.16 4.76 1.18-4.76h1.14l1.19 4.73 1.23-4.73h1l-1.76 6h-1.05l-1.23-4.67-1.19 4.67h-1.08l-1.74-6z" />
      <path d="m105.44 77a1.47 1.47 0 0 0 .25.78 1.69 1.69 0 0 0 1.36.49 2.11 2.11 0 0 0 1-.25.81.81 0 0 0 .43-.76.64.64 0 0 0 -.35-.59 4.36 4.36 0 0 0 -.88-.29l-.82-.2a4.12 4.12 0 0 1 -1.16-.43 1.27 1.27 0 0 1 -.66-1.14 1.76 1.76 0 0 1 .62-1.39 2.55 2.55 0 0 1 1.69-.53 2.35 2.35 0 0 1 2 .8 1.7 1.7 0 0 1 .37 1.1h-1a1.17 1.17 0 0 0 -.25-.62 1.59 1.59 0 0 0 -1.24-.4 1.51 1.51 0 0 0 -.9.22.68.68 0 0 0 -.3.58.71.71 0 0 0 .4.63 2.48 2.48 0 0 0 .68.24l.68.17a5.2 5.2 0 0 1 1.5.51 1.37 1.37 0 0 1 .59 1.22 1.88 1.88 0 0 1 -.62 1.4 2.66 2.66 0 0 1 -1.89.58 2.54 2.54 0 0 1 -1.93-.61 2.31 2.31 0 0 1 -.61-1.51z" />
      <path d="m110.85 77.66h1.15v1.23h-1.18z" />
      <path d="m117.27 70.61h3.73a2.48 2.48 0 0 1 1.77.62 2.27 2.27 0 0 1 .68 1.75 2.51 2.51 0 0 1 -.61 1.69 2.25 2.25 0 0 1 -1.84.72h-2.59v3.5h-1.12zm4.35 1.14a2.34 2.34 0 0 0 -1-.18h-2.22v2.87h2.22a2.09 2.09 0 0 0 1.22-.32 1.27 1.27 0 0 0 .46-1.13 1.23 1.23 0 0 0 -.68-1.24z" />
      <path d="m129.11 73.45a3.08 3.08 0 0 1 .8 2.29 3.92 3.92 0 0 1 -.71 2.41 2.54 2.54 0 0 1 -2.19 1 2.43 2.43 0 0 1 -2-.85 3.3 3.3 0 0 1 -.73-2.26 3.62 3.62 0 0 1 .76-2.42 2.59 2.59 0 0 1 2.07-.9 2.75 2.75 0 0 1 2 .73zm-.63 4a4 4 0 0 0 .37-1.7 3 3 0 0 0 -.27-1.37 1.5 1.5 0 0 0 -1.46-.84 1.44 1.44 0 0 0 -1.34.71 3.33 3.33 0 0 0 -.42 1.71 2.88 2.88 0 0 0 .42 1.61 1.46 1.46 0 0 0 1.33.64 1.39 1.39 0 0 0 1.37-.74z" />
      <path d="m134.47 73a2.71 2.71 0 0 1 .61.59v-3h1v8.31h-.91v-.84a2.31 2.31 0 0 1 -.84.81 2.45 2.45 0 0 1 -1.11.25 2.25 2.25 0 0 1 -1.74-.85 3.34 3.34 0 0 1 -.74-2.26 3.92 3.92 0 0 1 .68-2.29 2.19 2.19 0 0 1 1.91-1 2.11 2.11 0 0 1 1.14.28zm-2.26 4.53a1.44 1.44 0 0 0 1.31.65 1.36 1.36 0 0 0 1.15-.61 2.87 2.87 0 0 0 .45-1.74 2.58 2.58 0 0 0 -.46-1.69 1.45 1.45 0 0 0 -1.15-.55 1.53 1.53 0 0 0 -1.24.59 2.65 2.65 0 0 0 -.47 1.72 3 3 0 0 0 .41 1.67z" />
      <path d="m141.57 73.17a2.42 2.42 0 0 1 .77 1.71h-1a1.89 1.89 0 0 0 -.41-.93 1.33 1.33 0 0 0 -1-.36 1.42 1.42 0 0 0 -1.39.94 3.79 3.79 0 0 0 -.27 1.52 2.88 2.88 0 0 0 .38 1.52 1.31 1.31 0 0 0 1.21.62 1.35 1.35 0 0 0 1-.38 2.07 2.07 0 0 0 .51-1.06h1a2.68 2.68 0 0 1 -.85 1.76 2.64 2.64 0 0 1 -1.73.56 2.31 2.31 0 0 1 -1.89-.87 3.32 3.32 0 0 1 -.71-2.16 3.63 3.63 0 0 1 .78-2.48 2.49 2.49 0 0 1 2-.88 2.64 2.64 0 0 1 1.6.49z" />
      <path d="m146.59 75.24a.56.56 0 0 0 .47-.3 1 1 0 0 0 .06-.39.83.83 0 0 0 -.36-.75 2 2 0 0 0 -1.06-.24 1.38 1.38 0 0 0 -1.13.43 1.52 1.52 0 0 0 -.24.71h-.94a1.76 1.76 0 0 1 .72-1.56 3 3 0 0 1 1.61-.43 3.28 3.28 0 0 1 1.73.4 1.37 1.37 0 0 1 .66 1.26v3.48a.42.42 0 0 0 .07.26q.06.09.27.09h.15.18v.75l-.36.09h-.34a.83.83 0 0 1 -.76-.37 1.43 1.43 0 0 1 -.17-.56 2.71 2.71 0 0 1 -.89.71 2.77 2.77 0 0 1 -1.28.3 1.93 1.93 0 0 1 -1.38-.51 1.72 1.72 0 0 1 -.53-1.28 1.64 1.64 0 0 1 .52-1.3 2.48 2.48 0 0 1 1.38-.57zm-2.14 2.76a1.2 1.2 0 0 0 .76.25 2.25 2.25 0 0 0 1-.25 1.39 1.39 0 0 0 .85-1.34v-.82a1.78 1.78 0 0 1 -.48.2 4.21 4.21 0 0 1 -.58.11l-.61.08a2.54 2.54 0 0 0 -.83.23.91.91 0 0 0 -.47.85.85.85 0 0 0 .36.69z" />
      <path d="m150.38 77a1.47 1.47 0 0 0 .26.78 1.66 1.66 0 0 0 1.36.49 2.07 2.07 0 0 0 1-.25.82.82 0 0 0 .44-.76.65.65 0 0 0 -.35-.59 4.53 4.53 0 0 0 -.89-.29l-.82-.2a3.91 3.91 0 0 1 -1.15-.43 1.26 1.26 0 0 1 -.67-1.14 1.74 1.74 0 0 1 .63-1.39 2.54 2.54 0 0 1 1.68-.53 2.38 2.38 0 0 1 2 .8 1.82 1.82 0 0 1 .37 1.1h-1a1.1 1.1 0 0 0 -.24-.59 1.59 1.59 0 0 0 -1.24-.4 1.48 1.48 0 0 0 -.89.22.69.69 0 0 0 -.31.58.73.73 0 0 0 .4.63 2.62 2.62 0 0 0 .68.24l.68.17a5 5 0 0 1 1.5.51 1.37 1.37 0 0 1 .59 1.22 1.88 1.88 0 0 1 -.62 1.4 2.66 2.66 0 0 1 -1.89.58 2.54 2.54 0 0 1 -1.93-.61 2.31 2.31 0 0 1 -.57-1.54z" />
      <path d="m155.75 71.17h1v1.68h1v.83h-1v3.94a.44.44 0 0 0 .21.43 1 1 0 0 0 .4.06h.35v.8a1.9 1.9 0 0 1 -.36.08l-.35.01a1.08 1.08 0 0 1 -1-.37 1.59 1.59 0 0 1 -.26-.94v-4h-.81v-.83h.81z" />
      <path d="m159.36 77a1.47 1.47 0 0 0 .26.78 1.66 1.66 0 0 0 1.36.49 2.07 2.07 0 0 0 1-.25.82.82 0 0 0 .44-.76.65.65 0 0 0 -.35-.59 4.36 4.36 0 0 0 -.88-.29l-.82-.2a4 4 0 0 1 -1.16-.43 1.27 1.27 0 0 1 -.66-1.14 1.73 1.73 0 0 1 .62-1.39 2.55 2.55 0 0 1 1.69-.53 2.36 2.36 0 0 1 2 .8 1.76 1.76 0 0 1 .37 1.1h-1a1.1 1.1 0 0 0 -.23-.59 1.58 1.58 0 0 0 -1.24-.4 1.48 1.48 0 0 0 -.89.22.68.68 0 0 0 -.3.58.71.71 0 0 0 .4.63 2.52 2.52 0 0 0 .67.24l.69.17a5 5 0 0 1 1.49.51 1.35 1.35 0 0 1 .59 1.22 1.84 1.84 0 0 1 -.62 1.4 2.65 2.65 0 0 1 -1.88.58 2.56 2.56 0 0 1 -1.94-.61 2.3 2.3 0 0 1 -.6-1.51z" />
      <path d="m164.78 77.66h1.22v1.23h-1.18z" />
      <path
        d="m22.57 24v2.34a.46.46 0 0 1 -.45.47h-2.85a.22.22 0 0 0 -.23.23v12.4a.47.47 0 0 1 -.46.47h-2.28a.46.46 0 0 1 -.45-.47v-12.38a.22.22 0 0 0 -.23-.23h-2.85a.47.47 0 0 1 -.46-.47v-2.36a.47.47 0 0 1 .46-.47h9.35a.46.46 0 0 1 .45.47zm12.87-.47h-2.44a.48.48 0 0 0 -.48.46v10.85c0 1-.85 1.84-2.31 1.84s-2.21-.83-2.21-1.84v-10.84a.47.47 0 0 0 -.47-.46h-2.43a.47.47 0 0 0 -.47.46v10.84a5.3 5.3 0 0 0 5.61 5.09c3.29 0 5.67-2.3 5.67-5.09v-10.84a.47.47 0 0 0 -.47-.45zm12.33 0h-2.28a.47.47 0 0 0 -.49.47v8.8c0 .14-.16.19-.2 0l-3.1-8.8a.56.56 0 0 0 -.57-.42h-2.71a.47.47 0 0 0 -.42.42v15.46a.47.47 0 0 0 .46.47h2.24a.47.47 0 0 0 .46-.47v-8.09c0-.14.16-.19.2-.05l3.1 8.22a.6.6 0 0 0 .62.39h2.69a.46.46 0 0 0 .45-.47v-15.46a.46.46 0 0 0 -.45-.45zm12.31 13.13h-5.37a.22.22 0 0 1 -.23-.24v-2.81a.22.22 0 0 1 .23-.23h5.1a.46.46 0 0 0 .45-.47v-2.34a.46.46 0 0 0 -.45-.47h-5.1a.22.22 0 0 1 -.23-.23v-2.81a.22.22 0 0 1 .23-.23h5.29a.47.47 0 0 0 .46-.47v-2.36a.47.47 0 0 0 -.46-.47h-8.24a.47.47 0 0 0 -.46.47v15.46a.47.47 0 0 0 .46.47h8.32a.47.47 0 0 0 .46-.47v-2.34a.47.47 0 0 0 -.46-.46zm54.84-34.91v36.07a1.73 1.73 0 0 1 -1.73 1.74h-39.64v9.89a1.74 1.74 0 0 1 -1.73 1.75h-70.1a1.74 1.74 0 0 1 -1.72-1.75v-36.07a1.73 1.73 0 0 1 1.72-1.74h68.38v-9.89a1.73 1.73 0 0 1 1.72-1.75h41.37a1.73 1.73 0 0 1 1.73 1.75zm-45.15 13.61h-66.4a.29.29 0 0 0 -.29.29v32.18a.29.29 0 0 0 .29.29h66.11a.29.29 0 0 0 .29-.29zm20.52 9.64a.45.45 0 0 0 -.44-.46h-1.85a.22.22 0 0 1 -.22-.24v-9.64a.22.22 0 0 1 .22-.24h1.67a.46.46 0 0 0 .44-.47v-2.22a.46.46 0 0 0 -.44-.47h-6.92a.46.46 0 0 0 -.44.47v2.27a.46.46 0 0 0 .44.47h1.66a.22.22 0 0 1 .23.24v9.59a.22.22 0 0 1 -.23.24h-1.88a.46.46 0 0 0 -.45.46v2.23a.46.46 0 0 0 .45.47h7.32a.46.46 0 0 0 .44-.47zm13.34-13.22a.46.46 0 0 0 -.45-.47h-2.28a.46.46 0 0 0 -.46.47v8.8c0 .14-.16.19-.21 0l-3.12-8.9a.57.57 0 0 0 -.57-.42h-2.71a.47.47 0 0 0 -.46.47v15.45a.47.47 0 0 0 .46.47h2.28a.46.46 0 0 0 .45-.47v-8.1c0-.14.16-.19.21 0l3.1 8.22a.59.59 0 0 0 .61.4h2.7a.46.46 0 0 0 .45-.47z"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

TuneInLogoLockup.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
};

export default TuneInLogoLockup;
